// @mixin div-same-size-as-background-img($url) {
//   background-image: url($url);
//   background-size: contain;
//   background-repeat: no-repeat;
//   width: 100%;
//   height: 0;
//   padding-top: percentage(image-height($url) / image-width($url));
// }

// .items{
//   // margin: 1.5em auto;
//   // max-width: 768px;
//   column-gap: 1.5em;
//   // column-count: 4;
//   // column-gap: 1em;
//   background-color: #000000;
//   padding: 1.5em;

//   .item { /* Masonry bricks or child elements */
//     background: #fff;
//     padding: 1em;
//     margin: 0 0 1.5em;
//     // background-color: #eee;
//   //   display: inline-block;
//   //   margin: 0 0 1em;
//   //   width: 100%;
//   }

//   /* Masonry on large screens */
//   @media only screen and (min-width: 1024px) {
//     column-count: 3;
//   }

//   /* Masonry on medium-sized screens */
//   @media only screen and (max-width: 1023px) and (min-width: 768px) {
//     column-count: 3;
//   }

//   /* Masonry on small screens */
//   @media only screen and (max-width: 767px) and (min-width: 540px) {
//     column-count: 2;
//   }
// }

// .items{
//   display: block;
//   background-color: $black;
//   padding: 20px;

//   .item{
//     background: #fff;
//     height: 200px;
//     margin-bottom: 20px;
//     float: left;

//     &:nth-child(1){
//       width: 100%;
//     }

//     &:nth-child(2),
//     &:nth-child(3){
//       width: calc(33.3333% - 14px);
//       margin-right: 20px;
//     }

//     &:nth-child(4){
//       width: calc(33.3333% - 14px);
//     }


//   }

// }


.portfolio-items{
  display: flex;
  flex-wrap: wrap;
  background-color: $black;
  padding: 15px 7px;

  [class*="col-"]{
    padding-left: 7px;
    padding-right: 7px;
  }

  .row{
    margin-left: -7px;
    margin-right: -7px;
  }

  .portfolio-item{
    // display: flex;
    // align-items: center;
    // justify-content: center;
    position: relative;
    color: #fff;
    font-size: 80px;
    text-shadow: 0px 0px 35px rgba(0, 0, 0, 1);
    background: #fff;
    margin-bottom: 15px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    &.h200{
      height: 200px;
    }

    &.h400{
      height: 400px;
    }

    &.h415{
      height: 415px;
    }

    .mask{

      a{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        opacity: 0;
        background-color: rgba(255, 0, 108, 0.90);
        transition: all 0.8s ease-in-out;
        outline: 0;
        text-decoration: none;

        &:hover {
          opacity: 1;

          h5,
          p{
            opacity: 1;
            transform: translateY(0px);
          }

          p{
            transition-delay: 0.1s;
          }
        }

        h5{
          @include dinpro-light();
          font-size: 20px;
          color: $white;
          text-transform: uppercase;
          border-bottom: 1px solid $white;
          padding-bottom: 10px;
          transform: translateY(-100px);
          opacity: 0;
          transition: all 0.2s ease-in-out;

          // @media (min-width: $breakpoint-md){
          //   font-size: 20px;
          // }

          @media (min-width: $breakpoint-xl){
            font-size: 30px;
          }

        }

        p{
          @include dinpro-light();
          font-size: 20px;
          color: $white;
          text-transform: uppercase;
          transform: translateY(100px);
          opacity: 0;
          transition: all 0.2s linear;
        }

      }

    }

    &[data-item="1"]{
      background-image: url(../assets/images/portfolio/grid/power-to-grow/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/power-to-grow/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/power-to-grow/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/power-to-grow/xl.jpg);
      }

      // @media (min-width: $breakpoint-xl) {
      //   background-image: url(../assets/images/portfolio/grid/power-to-grow/xxl.jpg);
      // }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/power-to-grow/xxxl.jpg);
      }
    }

    &[data-item="2"]{
      background-image: url(../assets/images/portfolio/grid/pay-by-court/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/pay-by-court/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/pay-by-court/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/pay-by-court/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/pay-by-court/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/pay-by-court/xxxl.jpg);
      }
    }

    &[data-item="3"]{
      background-image: url(../assets/images/portfolio/grid/maven-women/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/maven-women/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/maven-women/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/maven-women/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/maven-women/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/maven-women/xxxl.jpg);
      }
    }

    &[data-item="4"]{
      background-image: url(../assets/images/portfolio/grid/don-curvino/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/don-curvino/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/don-curvino/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/don-curvino/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/don-curvino/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/don-curvino/xxxl.jpg);
      }
    }

    &[data-item="5"]{
      background-image: url(../assets/images/portfolio/grid/mastiff/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/mastiff/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/mastiff/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/mastiff/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/mastiff/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/mastiff/xxxl.jpg);
      }
    }

    &[data-item="6"]{
      background-image: url(../assets/images/portfolio/grid/keteka/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/keteka/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/keteka/xs.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/keteka/lg.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/keteka/xl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/keteka/xxxl.jpg);
      }
    }

    &[data-item="7"]{
      background-image: url(../assets/images/portfolio/grid/sigora/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/sigora/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/sigora/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/sigora/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/sigora/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/sigora/xxxl.jpg);
      }
    }

    &[data-item="8"]{
      background-image: url(../assets/images/portfolio/grid/adelante/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/adelante/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/adelante/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/adelante/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/adelante/xl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/adelante/xxxl.jpg);
      }
    }

    &[data-item="9"]{
      background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/dreaming-out-loud/xxxl.jpg);
      }
    }

    &[data-item="10"]{
      background-image: url(../assets/images/portfolio/grid/agora/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/agora/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/agora/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/agora/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/agora/xxl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/agora/xxxl.jpg);
      }
    }

    &[data-item="11"]{
      background-image: url(../assets/images/portfolio/grid/africa-b2b/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/africa-b2b/md.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/africa-b2b/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/africa-b2b/xl.jpg);
      }

      @media (min-width: $breakpoint-xl) {
        background-image: url(../assets/images/portfolio/grid/africa-b2b/xl.jpg);
      }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/africa-b2b/xxxl.jpg);
      }
    }

    &[data-item="12"]{
      background-image: url(../assets/images/portfolio/grid/rioslum/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/rioslum/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/rioslum/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/rioslum/xl.jpg);
      }

      // @media (min-width: $breakpoint-xl) {
      //   background-image: url(../assets/images/portfolio/grid/rioslum/xxl.jpg);
      // }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/rioslum/xxxl.jpg);
      }
    }

    &[data-item="13"]{
      background-image: url(../assets/images/portfolio/grid/plympton-farms/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/plympton-farms/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/plympton-farms/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/plympton-farms/xl.jpg);
      }

      // @media (min-width: $breakpoint-xl) {
      //   background-image: url(../assets/images/portfolio/grid/plympton-farms/xxl.jpg);
      // }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/plympton-farms/xxxl.jpg);
      }
    }

    &[data-item="14"]{
      background-image: url(../assets/images/portfolio/grid/i-dev/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/i-dev/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/i-dev/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/i-dev/xl.jpg);
      }

      // @media (min-width: $breakpoint-xl) {
      //   background-image: url(../assets/images/portfolio/grid/i-dev/xxl.jpg);
      // }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/i-dev/xxxl.jpg);
      }
    }

    &[data-item="15"]{
      background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/xs.jpg);

      @media (min-width: $breakpoint-sm) {
        background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/sm.jpg);
      }

      @media (min-width: $breakpoint-md) {
        background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/lg.jpg);
      }

      @media (min-width: $breakpoint-lg) {
        background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/xl.jpg);
      }

      // @media (min-width: $breakpoint-xl) {
      //   background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/xxl.jpg);
      // }

      @media (min-width: $breakpoint-xxl) {
        background-image: url(../assets/images/portfolio/grid/fair-trade-fashion/xxxl.jpg);
      }
    }
  }
}


