footer{
  background-color: $white;
  padding: 30px 0;

  .text{
    @include text-4();
    
    a { color: $monzagrey; }
  }

  ul.links{
    width: 100%;
    text-decoration: none;
    list-style-type: none;
    margin: 0;
    // padding: 0 10px 0 0;
    padding: 0 40px 0 0;
    transform: rotate(0deg);
    text-align: left;
    margin-bottom: 30px;

    @media (min-width: $breakpoint-md) {
      width: 130px;
      transform: rotate(-90deg);
      text-align: right;
      margin-bottom: 0;
      padding: 0 25px 0 0;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 0 25px 0 0;
    }

    li.link{
      @include text-8();
      text-transform: uppercase;
      color: $monzagrey;

      a{
        display: block;
        @include text-10();
        text-transform: uppercase;
        text-decoration: none;
        @include transition(1s);
      }
    }
  }

  figure.footer-logo{
    text-align: center;
  }
}