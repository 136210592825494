.submenu{
  padding: 5px 0;
  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    float: right;

    li{
      position: relative;
      @include text-8();
      text-transform: uppercase;
      margin-right: 15px;
      float: left;
      @include transition(1s);
      font-size: 14px;

      @media (min-width: $breakpoint-sm){
        font-size: 18px;
      }

      &:hover{
        // text-decoration: underline;
        cursor: pointer;

        &:after{
          -webkit-transform-origin: left center;
          transform-origin: left center;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 2px;
        background: #fff;
        margin-top: -1px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right center;
        transform-origin: right center;
        -webkit-transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
        transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
        transition: transform .4s cubic-bezier(.7,0,.3,1);
        transition: transform .4s cubic-bezier(.7,0,.3,1),-webkit-transform .4s cubic-bezier(.7,0,.3,1);
      }
    }
  }
}