.items{
  // text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: row;
  margin: 0;
  padding: 0;

  /* three items */
  .item:first-child:nth-last-child(3),
  .item:first-child:nth-last-child(3) ~ .item {
    width: 100%;

    // &:last-child{
    //   width: 100%;
    // }
    .name{
      font-size: 18px;
    }

    @media (min-width: $breakpoint-sm) {
      width: 33.3333%;

      // &:last-child{
      //   width: 33.3333%;
      // }
    }

    @media (min-width: $breakpoint-md) {
      .name{
        font-size: 22px;
      }
    }
  }

  /* five items */
  .item:first-child:nth-last-child(5),
  .item:first-child:nth-last-child(5) ~ .item {
    width: 33.3333%;

    &:nth-child(4),
    &:nth-child(5){
      width: 50%;
    }

    .name{
      font-size: 17px;
    }

    @media (min-width: $breakpoint-sm) {
      width: 20%;

      &:nth-child(4),
      &:nth-child(5){
          width: 20%;
      }

      .name{
        font-size: 18px;
      }
    }

    @media (min-width: $breakpoint-md) {
      width: 20%;

      &:nth-child(4),
      &:nth-child(5){
          width: 20%;
      }
    }
  }

  .item{
    list-style: none;
    // flex-grow: 1;
    // flex-basis: 0;

    figure.image{
      text-align: center;
      width: 60px;
      height: 60px;
      margin: 0 auto 20px auto;
      display: flex;
      align-content: center;
      justify-content: center;

      img{
        width: 44px;
      }
    }

    .name{
      @include text-2();
      text-align: center;
      text-transform: uppercase;
    }
  }
}


.swipe {
  // display: inline-block;
  // margin: 0.5em;
  position: relative;
  // text-decoration: none;
  // color: #fff;
  // font-weight: bold;
  // text-transform: uppercase;
  // padding: 1em 1.25em 1.25em;
  // font-size: 1.25em;
  overflow: hidden;
  z-index: 2;
  transition: transform 0.4s ease, box-shadow 0.4s ease;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease;
  }

  &:before {
    // background: $orangesun;
    // transform: translateX(-101%);
    z-index: -1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
    transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
    transition: transform .4s cubic-bezier(.7,0,.3,1);
    transition: transform .4s cubic-bezier(.7,0,.3,1),-webkit-transform .4s cubic-bezier(.7,0,.3,1);
  }

  &:after {
    background: $fuscagrey;
    z-index: -2;
  }

  &:hover {

    &:before {
      // transform: translateX(0);
      -webkit-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}