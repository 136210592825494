/* Slider */
.slick-slider{
	position: relative;

	display: block;
	box-sizing: border-box;

	-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
					user-select: none;

	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
			touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list
{
	position: relative;

	display: block;
	overflow: hidden;

	margin: 0;
	padding: 0;
}
.slick-list:focus
{
	outline: none;
}
.slick-list.dragging
{
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
	-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
				-o-transform: translate3d(0, 0, 0);
					transform: translate3d(0, 0, 0);
}

.slick-track
{
	position: relative;
	top: 0;
	left: 0;

	display: block;
}
.slick-track:before,
.slick-track:after
{
	display: table;

	content: '';
}
.slick-track:after
{
	clear: both;
}
.slick-loading .slick-track
{
	visibility: hidden;
}

.slick-slide
{
	display: none;
	float: left;

	height: 100%;
	min-height: 1px;
}
[dir='rtl'] .slick-slide
{
	float: right;
}
.slick-slide img
{
	display: block;
}
.slick-slide.slick-loading img
{
	display: none;
}
.slick-slide.dragging img
{
	pointer-events: none;
}
.slick-initialized .slick-slide
{
	display: block;
}
.slick-loading .slick-slide
{
	visibility: hidden;
}
.slick-vertical .slick-slide
{
	display: block;

	height: auto;

	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}

.slick-prev,
.slick-next{
  display: inline-block;
  position: absolute;
	top: 150px;
	width: 30px;
	height: 30px;
	font-size: 0;
	line-height: 0;
  border-style: solid;
  border-color: #ef7328;
	border-width: 0 2px 2px 0;
  background-color: transparent;
  padding: 3px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 4;
	outline: 0;
  cursor: pointer;

	@media (min-width: $breakpoint-sm){
		top: 150px;
	}


	&:focus,
	&:active,
	&:hover{
		outline: 0;
	}
}

.slick-prev{
	left: 15px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);

	@media (min-width: $breakpoint-md){
		left: 0;
	}
}

.slick-next{
	right: 15px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);

	@media (min-width: $breakpoint-md){
		right: 0;
	}
}

