/*=====================
 * DEFINITIONS
 *=====================*/

 body {
  position: relative;
  color: $monzagrey;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-attachment: fixed;
  background-size: cover;
}

 a,
 a:hover,
 a:active,
 a:focus{
   text-decoration: none;
 }

//  h1, h2, h3, h4, h5, h6{
//    margin-bottom: 0.5rem;

//    @media (min-width: $breakpoint-sm) {
//     margin-bottom: 1rem;
//   }
//  }

/*=====================
 * HELPERS
 *=====================*/

.transition{
  @include transition(1s);
}

.grayscale {
  @include grayscale(100%);

  &:hover{
      @include grayscale(0);
  }
}

.no-radius{
  border-radius: 0;
}

.no-m{
  margin: 0;
}

.pd-y-xs-10p{
  padding: 0px 10% !important;
}

.pd-y-md-10p{

  @media (min-width: $breakpoint-md) {
    padding: 0px 10% !important;
  }
}

.pd-y-xs-12p{
  padding: 0px 12% !important;
}

.pd-y-md-18p{

  @media (min-width: $breakpoint-md) {
    padding: 0px 18% !important;
  }
}

.pd-t-15px{
  padding-top: 15px !important;
}

.pd-b-30px{
  padding-bottom: 30px !important;
}

.pd-b-50px{
  padding-bottom: 50px !important;
}

.mt-30px{
  margin-top: 30px !important;
}

.mb-5px{
  margin-bottom: 5px !important;
}

.mb-10px{
  margin-bottom: 10px !important;
}

.mb-15px{
  margin-bottom: 15px !important;
}

.mb-20px{
  margin-bottom: 20px !important;
}

.mb-30px{
  margin-bottom: 30px !important;
}

.mb-50px{
  margin-bottom: 50px !important;
}

.mb-60px{
  margin-bottom: 60px !important;
}

.mb-70px{
  margin-bottom: 70px !important;
}

.mb-120px{
  margin-bottom: 120px !important;
}

.mw-900px{
  max-width: 900px;
}

.m-auto{
  margin: 0 auto;
}

.text-uppercase{
  text-transform: uppercase;
}

.m-y-20px{
  margin: 20px 0 !important;
}

.m-y-30px{
  margin: 30px 0 !important;
}

.m-y-60px{
  margin: 60px 0 !important;
}

/*=====================
 * COLORS
 *=====================*/

.color--white{
  color: $white !important;
}

.color--black{
  color: $black !important;
}

.color--formgrey{
  color: $formgrey !important;
}

.color--fuscagrey{
  color: $fuscagrey !important;
}

.bg--white{
  background-color: $white !important;
}

.bg--black{
  background-color: $black !important;
}


/*=====================
 * SEP
 *=====================*/

[class^="sep-"] {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: $blocogrey;
}

.sep-1 {
  height: 1px;
}



/*=====================
 * BUTTONS
 *=====================*/

.button{
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-style: solid;
  border-width: 3px;
  border-radius: none;
  padding: .375rem .75rem;
  font-size: 10px;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  @include text-9();
  @include transition(1s);

  &:focus{
    outline: 0;
  }

  &:hover{
    text-decoration: none;
  }

  &.state-await{
    background-color: $orangesun;
    color: $white !important;
    border: 2px solid $orangesun;
    padding: .375rem 2.75rem;
  }

  &.state-sending{
    background-color: $white;
    color: $orangesun !important;
    border: 2px solid $orangesun;
    padding: .375rem 2.75rem;
    pointer-events: none;
  }

  &.state-success{
    background-color: $white;
    color: $orangesun !important;
    border: 2px solid $orangesun;
    padding: .375rem 2.75rem;
  }

  @media (min-width: $breakpoint-sm) {
    // font-size: 11px;
  }

  @media (min-width: $breakpoint-md) {
    font-size: 16px;
  }
}
