.parallax-banner-video{
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;

  @media (min-width: $breakpoint-md) {
    height: 350px;
  }

  [id^="jarallax-container-"]{
    &:after{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
    }
  }

  h2{
    @include h1();
    text-transform: uppercase;
    text-align: center;
    font-size: 60px;

    @media (min-width: $breakpoint-sm){
      font-size: 110px;
    }
  }

  &.with-arrow{
    &:after{
      position: absolute;
      bottom: -1px;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 30px;
      margin-left: -30px;
    }
  }

  &.with-arrow-down{
    &:before,
    &:after {
      content:'';
      position: absolute;
      bottom: 0;
      width: 50%;
      background-color: $white;
      padding-bottom:6%;

      @media (min-width: $breakpoint-sm) {
        padding-bottom:3%;
        bottom: -1px;
      }

      @media (min-width: $breakpoint-lg) {
        padding-bottom:3%;
        bottom: 0;
      }
    }

    &:before{
      right: 50%;
      -ms-transform-origin: 100% 100%;
      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
      -ms-transform: skewX(45deg);
      -webkit-transform: skewX(45deg);
      transform: skewX(45deg);
    }

    &:after{
      left: 50%;
      -ms-transform-origin: 0 100%;
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
      -ms-transform: skewX(-45deg);
      -webkit-transform: skewX(-45deg);
      transform: skewX(-45deg);
    }

    .video{
      position: fixed;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      // background: url(../assets/images/full-background.png) no-repeat;
      background-size: cover;
    }
    // &:after{
    //   position: absolute;
    //   bottom: -1px;
    //   left: 50%;
    //   border: solid transparent;
    //   content: " ";
    //   height: 0;
    //   width: 0;
    //   position: absolute;
    //   pointer-events: none;
    //   border-color: rgba(255, 255, 255, 0);
    //   border-bottom-color: #ffffff;
    //   border-width: 30px;
    //   margin-left: -30px;
    // }
  }

  &[data-section="about"]{
    // background-color: aqua;
    // background-image: url(../assets/video.mp4);

    @media (min-width: $breakpoint-sm) {
      // background-image: url(../images/parallax/br_accelerator-1_desktop.jpg);
    }

    // h2{
    //   text-align: center;
    //   line-height: 30px;

    //   @media (min-width: $breakpoint-sm) {
    //     line-height: 40px;
    //   }

    //   @media (min-width: $breakpoint-md) {
    //     line-height: 60px;
    //   }
    // }
  }
}