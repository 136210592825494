/*=====================
 * CSS
 *=====================*/

$browser-context: 10;

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em
}

@mixin transition($time) {
  -webkit-transition: all $time ease;
   -moz-transition: all $time ease;
     -o-transition: all $time ease;
    -ms-transition: all $time ease;
        transition: all $time ease;
}

@mixin grayscale($time) {
  -webkit-filter: grayscale($time);
  filter: grayscale($time);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}