.parallax{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: $breakpoint-md) {
    height: 485px !important;
    padding-right: 15px;
  }

  @media (min-width: $breakpoint-lg) {
    padding-right: 150px;
  }

  &.work-1{

    .content{
      background-color: $orangesun;
    }
  }

  &.work-2{

    .content{
      background-color: $orangesun;
    }
  }

  &.about-1{

    .content{
      background-color: $orangesun;
    }
  }

  &.services-1{
    height: 285px !important;

    @media (min-width: $breakpoint-md) {
      height: 485px !important;
    }
  }

  &.services-2{
    height: 285px !important;

    @media (min-width: $breakpoint-md) {
      height: 485px !important;
    }
  }

  &.startup-1{
    height: 285px !important;

    @media (min-width: $breakpoint-md) {
      height: 485px !important;
    }
  }

  .content{
    padding: 30px 15px;
    text-align: center;

    @media (min-width: $breakpoint-md) {
      max-width: 500px;
      background-color: transparent !important;
      padding: 0;
      text-align: left
    }

    .title{
      @include dinpro-black();
      font-size: 45px;
      color: #fff;
      margin: 0 0 15px 0;
      text-align: center;

      @media (min-width: $breakpoint-md) {
        display: none;
      }
    }

    .text{
      @include text-3();
      color: #fff;
      margin: 0 0 50px 0;
      font-size: 18px;

      @media (min-width: $breakpoint-sm) {
        font-size: 22px;
      }

      @media (min-width: $breakpoint-md) {
        margin: 0 0 70px 0;
      }
    }
  }
}

