.portfolio{
  position: relative;

  .close{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav{
    outline: 0;
  }

  .image-gallery-fullscreen-button::before,
  .image-gallery-play-button::before,
  .image-gallery-left-nav::before,
  .image-gallery-right-nav::before{
    text-shadow: none;
    color: $orangesun;
  }

  .image-gallery-fullscreen-button:hover::before,
  .image-gallery-play-button:hover::before,
  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before{
    color: $orangesun;
  }
  .image-gallery-slides{
    // overflow-x: hidden;
    // overflow-y: inherit;

    .image-gallery-slide{
      // position: relative;
      .image-gallery-image{
        padding-bottom: 180px;

        @media (min-width: $breakpoint-sm){
          padding-bottom: 90px;
        }

        @media (min-width: $breakpoint-lg){
          padding-bottom: 0;
        }

        &.maven-women{
          // margin-top: 90px;

          img{
            display: block;
            width: 100%;
            margin: 0 auto;

            @media (min-width: $breakpoint-sm){
              width: 60%;
            }
          }

          .play-button{
            margin: 50px auto auto auto !important;

            @media (min-width: $breakpoint-md){
              margin: 90px auto auto auto !important;
            }

            @media (min-width: $breakpoint-lg){
              margin: auto !important;
            }
          }
        }

        .iframe-video{
          width: 100% !important;

          @media (min-width: $breakpoint-sm){
            height: 400px !important;
          }

        }

        .image-gallery-description{
          display: inline-block;
          position: absolute;
          // top: 100%;
          bottom: 0;
          width: 100%;
          // height: 200px;
          @include dinpro-light();
          font-size: 16px;
          text-align: center;
          color: $monzagrey;
          background-color: transparent;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          padding: 20px;

          @media (min-width: $breakpoint-sm){
            max-width: 600px;
            // top: auto;
            // bottom: 0;
          }

          .image-gallery-title{
            display: block;
            width: 100%;
            @include dinpro-medium();
            font-size: 16px;
            text-align: center;
            margin-bottom: 10px;
          }
        }

        .play-button {
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 60px;
          width: 100px;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 5px;
        }

        .play-button:hover {
          background-color: rgba(0, 0, 0, 0.9);
        }

        .play-button:after {
          content: "";
          display: block;
          position: absolute;
          top: 16.5px;
          left: 40px;
          margin: 0 auto;
          border-style: solid;
          border-width: 12.5px 0 12.5px 20px;
          border-color: transparent transparent transparent rgba(255, 255, 255, 1);
        }

        .close-video{
          display: none;
        }
        .close-video::before {
          content: '✖';
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 20px;
          padding: 20px;
          z-index: 1;
          line-height: .7;
          display: block;
          color: #fff;
        }

        .video-wrapper {
          position: relative;
          padding: 33.35% 0; /* 16:9 */
          height: 0;
        }

        .video-wrapper iframe {
          // position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translateY(-50%);
        }



        // &:before{
        //   position: absolute;
        //   content: "Brand Package";
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   @include dinpro-medium();
        //   font-size: 16px;
        //   text-align: center;
        // }
      }
    }
  }
}

