.services{

  .service{
    // margin-bottom: 20px;
    padding: 20px 0;

    .title{
      @include dinpro-medium();
      font-size: 40px;
      color: $fuscagrey;
      text-transform: uppercase;
    }

    .text{
      @include text-4();
    }

    ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
      // margin: 0 0 20px 0;

      // &:last-child{
      //   margin: 0 0 20px 0;
      // }

      li{
        position: relative;
        @include text-4();
        padding-left: 20px;
        // background: url(../assets/arrow_bullet.svg) no-repeat;

        .arrow{
          position: absolute;
          top: 7px;
          left: 0;
          width: 10px;
          height: 10px;
          // border: solid #8b8b8b;
          border-style: solid;
          border-color: #8b8b8b;
          // border-top-width: medium;
          // border-right-width: medium;
          // border-bottom-width: medium;
          // border-left-width: medium;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          transition: all 1s ease;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
    }

    .video,
    .image{
      width: 100%;
    }
  }
}