
/*=====================
 * TYPOGRAPHY
 *=====================*/


/*=====================
 * TYPOGRAPHY FONTS
 *=====================*/

@mixin h1() {
  @include dinpro-black();
  font-size: 110px;
  color: $white;
}

@mixin text-1() {
  @include dinpro-light();
  font-size: 40px;
  color: $monzagrey;
}

@mixin text-2() {
  @include dinpro-light();
  font-size: 24px;
  color: $fuscagrey;
}

@mixin text-3() {
  @include roboto-light();
  font-size: 22px;
  color: $monzagrey;
}

@mixin text-4() {
  @include roboto-light();
  font-size: 16px;
  color: $fuscagrey;
}

@mixin text-5() {
  @include dinpro-light();
  font-size: 12px;
  color: $fuscagrey;
}

@mixin text-6() {
  @include dinpro-light();
  font-size: 26px;
  color: $fuscagrey;
}

@mixin text-7() {
  @include dinpro-medium();
  font-size: 26px;
  color: $fuscagrey;
}

@mixin text-8() {
  @include dinpro-light();
  font-size: 18px;
  color: $white;
}

@mixin text-9() {
  @include dinpro-medium();
  font-size: 20px;
  color: $black;
}

@mixin text-10() {
  @include dinpro-medium();
  font-size: 20px;
  color: $fuscagrey;
}

@mixin text-11() {
  @include playfair-regular();
  font-size: 29px;
  color: $fuscagrey;
}

@mixin text-12() {
  @include dinpro-light();
  // font-size: 34px;
  font-size: 25px;
  color: $formgrey;
}

@mixin text-13() {
  @include dinpro-medium();
  font-size: 27px;
  color: $white;
}

@mixin text-14() {
  @include dinpro-medium();
  font-size: 16px;
  color: $white;
}
