.contact-form{
  background-color: $white;
  padding: 30px 0;

  .title{
    @include text-1();
    text-align: center;
    text-transform: uppercase;
		margin: 0 0 20px 0;

    font-size: 30px;

    @media (min-width: $breakpoint-sm){
      font-size: 40px;
    }
  }

  .text{
    @include text-3();
    text-align: center;
		// margin: 0 0 15px 0;
    font-size: 18px;
    // max-width: 870px;
    margin: 0 auto 15px auto;

    @media (min-width: $breakpoint-sm){
      font-size: 21px;
    }

  }

	form.message{
    text-align: center;

		.form-group{

			.form-control{
				max-width: 450px;
        @include text-12();
        color: $fuscagrey;
        background-color: $blocogrey !important;
        border-radius: 0;
        margin: 0 auto;
        border: 0;
				// box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);

        &:focus{
          border: none;
          box-shadow: none;
        }

				&[type="text"],
				&[type="email"]{
          height: 45px;
				}

				&.error{
					border: 1px solid red;

					&::-webkit-input-placeholder {
					  color: pink;
					}
					&::-moz-placeholder {
					  color: pink;
					}
					&:-ms-input-placeholder {
					  color: pink;
					}
					&:-moz-placeholder {
					  color: pink;
					}
				}
			}
		}
	}
}