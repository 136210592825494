.testimonials{
  position: relative;
  padding: 0 30px;

  @media (min-width: $breakpoint-sm) {
    padding: 0 60px;
  }

  &:before{
    position: absolute;
    top: -15px;
    left: -0;
    width: 20px;
    height: 137px;
    content: "";
    background-image: url(../assets/quote-left.svg);
    background-repeat: no-repeat;

    @media (min-width: $breakpoint-sm) {
      top: -31px;
      left: 0;
      width: 40px;
    }
  }

  &:after{
    position: absolute;
    top: -15px;
    right: 0;
    width: 20px;
    height: 137px;
    content: "";
    background-image: url(../assets/quote-right.svg);
    background-repeat: no-repeat;

    @media (min-width: $breakpoint-sm) {
      top: -31px;
      right: 0;
      width: 40px;
    }
  }

  .text{
    @include text-11();
    font-style: italic;
    font-size: 14px;

    @media (min-width: $breakpoint-sm) {
      font-size: 18px;
    }
  }

  .image{
    margin: 0 auto 15px auto;
  }

  .author{
    @include text-3();
    font-size: 18px;
    margin-bottom: 10px;
  }

  .company{
    @include text-3();
    font-size: 11px;
  }
}