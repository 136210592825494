/*=====================
* BREAKPOINTS
*=====================*/

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1920px;
$breakpoint-xxxl: 2560px;

/*=====================
* COLORS
*=====================*/

$white: #FFFFFF;
$black: #000000;
$orangesun: #ef7328;
$monzagrey: #212121;
$fuscagrey: #393939;
$blocogrey: #e6e6e6;
$formgrey: #cacaca;
$bluesky: #00a3e6;
$greenfrog: #bed400;
$hotpink: #ff006c;
$meuteal: #00b6c1;