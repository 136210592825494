.work{

  .portfolio{

    .title{
      @include text-6();
      text-align: center;
      text-transform: uppercase;
      color: $white;
      background-color: $black;
      margin: 0;
      padding-top: 15px;
    }

    @import "PortfolioItems";
  }

}