.startups{

  .title{
    @include text-1();
    font-size: 30px;
    color: $fuscagrey;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: $breakpoint-sm){
      font-size: 40px;
    }
  }

  .text{
    @include text-3();
    font-size: 17px;

    @media (min-width: $breakpoint-sm){
      font-size: 22px;
    }
  }

  .testimonials-title{
    @include text-1();
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;

    @media (min-width: $breakpoint-sm){
      font-size: 40px;
    }
  }

  @import "./components/Testimonials";

  .slick-slider{
    margin: 0 auto;
    padding: 0 15px;

    @media (min-width: $breakpoint-sm){
      max-width: 700px;
      padding: 0 40px;
    }
  }

  // .items .item .name{
  //   display: inline-block;
  //   max-width: 270px;
  //   @include text-3();
  //   text-transform: initial;
  //   cursor: default;
  // }

  ul.images{
    display: inline-block;
    margin: 0;
    padding: 0;

    li.image{
      list-style: none;
      float: left;
    }
  }
}