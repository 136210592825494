.nav-small{
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $monzagrey;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 3;
	-webkit-box-shadow: -5px 0px 19px 1px rgba(0,0,0,0.3);
	-moz-box-shadow: -5px 0px 19px 1px rgba(0,0,0,0.3);
	box-shadow: -5px 0px 19px 1px rgba(0,0,0,0.3);
  z-index: 999999;

  &.hidden{
  	margin-right: -100%;
  }

  figure{
    margin: 15px;
    height: 30px;
    cursor: pointer;

	  .close{
      width: 30px;
      float: right;
	  }
  }

	ul.menu{
    list-style: none;
    padding-left: 0;
		margin: 0;

		li.menu-item{

			a{
				display: block;
				text-transform: uppercase;
        @include text-6();
				color: $white;
				padding: 20px;
			  @include transition(1s);

				&.active{
					color: $monzagrey;
					background-color: $monzagrey;
				}
			}
		}
	}
}