.header-fixed{
  position: fixed;
  // position: relative;
  top: 0;
  width: 100%;
  // z-index: 1;
  margin: 0;
  // opacity: 0;
  @include transition(2s);
  z-index: 99999;

  .main-menu{
    position: relative;
    padding: 15px 30px;
    background-color: rgba(250,250,250,0.7);
    background-color: $white;
    z-index: 99999;

    .content{
      display: flex;
      align-items: center;

      figure.logo{
        display: inline-block;
        width: 75px;
        height: 58px;
        margin: 0;
        flex: auto;
      }

      figure.toogle-nav{
        display: inline-block;
        margin: 0;
        flex: auto;
        cursor: pointer;

        @media (min-width: $breakpoint-lg) {
          display: none;
        }
      }

      @import "Nav";
    }
  }

  @import "SubMenu";

  .submenu {
    position: absolute;
    width: 100%;
    left: 0;
    @include transition(2s);
  }
}
