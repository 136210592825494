.about{

  .intro{
    margin-bottom: 30px;

    .title{
      @include text-1();
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .text{
      @include text-3();
      text-align: center;
      font-size: 17px;

      &.why{
        strong{
          font-size: 19px;
        }
      }

      strong{

        &.italic{
          font-style: italic;
        }
      }


      @media (min-width: $breakpoint-sm){
        text-align: left;
      }
    }

    ul.decimal{
      list-style: decimal;
      @include text-3();
      font-size: 14px;

      li{
        margin-bottom: 15px;
      }
    }

    .video{
      width: 100%;
    }
  }

  .team{
    background-color: $bluesky;
    padding: 20px 0;

    .title{
      @include text-1();
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px;
      color: $white;
    }

    .member{
      text-align: center;
      margin-bottom: 40px;

      @media (min-width: $breakpoint-md){
        text-align: left;
        margin-bottom: 40px;
      }

      .image{
        text-align: center;

        img{
          max-width: 125px;
          border-radius: 50%;
          filter: grayscale(1);
        }
      }
      .name{
        @include text-13();
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: center;
      }

      .role{
        @include text-14();
        text-align: center;
      }

      .description{
        @include text-4();
        color: $white;
      }
    }
  }

  .offices{
    border-bottom: 1px solid $formgrey;
    padding: 30px 0;

    .container{
      // max-width: 500px;
    }

    .title{
      @include text-1();
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 30px;
      font-size: 30px;

      @media (min-width: $breakpoint-sm){
        font-size: 40px;
      }
    }

    .text{
      @include text-4();
    }

    ul{
      list-style-type: none;
      margin: 0 0 20px 0;
      padding: 0;

      li{
        @include text-13();
        color: $monzagrey;
        padding: 5px 0;
        cursor: pointer;
        font-size: 19px;

        @media (min-width: $breakpoint-md){
          font-size: 27px;
        }

        &:not(:last-child){
          border-bottom: 1px solid $formgrey;
        }

        &:hover{
          color: $bluesky;
        }
      }
    }

    .map{
      width: 100%;
      height: 400px;
    }
  }
}