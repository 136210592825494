.header{

  &.fixed{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    margin-top: 0 !important;
    // @include transition(1);
  }

  .main-menu{
    padding: 15px 30px;
    background-color: rgba(250,250,250,0.7);

    .content{
      display: flex;
      align-items: center;

      figure.logo{
        display: inline-block;
        width: 75px;
        height: 58px;
        margin: 0;
        flex: auto;
      }

      figure.toogle-nav{
        display: inline-block;
        margin: 0;
        flex: auto;
        cursor: pointer;

        @media (min-width: $breakpoint-lg) {
          display: none;
        }
      }

      @import "Nav";
    }
  }

  @import "SubMenu";
}
