.header-nav{
  display: none;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  // background: black;


  @media (min-width: $breakpoint-lg) {
    display: inline-block;
  }

  li{
    float: left;

    // &:hover{
    //   margin: auto;
    //   background: linear-gradient( crimson , crimson) no-repeat 0 0;
    //   background-size: 0 100%;
    //   animation: stripes 1s linear infinite;
    // }

    &:not(:last-child){
      margin-right: 30px;
    }

    a{
      display: block;
      position: relative;
      @include text-6();
      text-transform: uppercase;
      text-decoration: none;
      @include transition(1s);

      &.active{
        pointer-events: none;

        &:after{
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &:hover{
        cursor: pointer;

        &:after{
          -webkit-transform-origin: left center;
          transform-origin: left center;
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 2px;
        // background: #000;
        margin-top: -1px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right center;
        transform-origin: right center;
        -webkit-transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
        transition: -webkit-transform .4s cubic-bezier(.7,0,.3,1);
        transition: transform .4s cubic-bezier(.7,0,.3,1);
        transition: transform .4s cubic-bezier(.7,0,.3,1),-webkit-transform .4s cubic-bezier(.7,0,.3,1);
      }

      &[href="#/"]{
        &:hover{
          color: $orangesun;
        }

        &:after{
          background: $orangesun;
        }
      }

      &[href="#/about"]{
        &:hover{
          color: $bluesky;
        }

        &:after{
          background: $bluesky;
        }
      }

      &[href="#/services"]{
        &:hover{
          color: $hotpink;
        }

        &:after{
          background: $hotpink;
        }
      }

      &[href="#/startups"]{
        &:hover{
          color: $meuteal;
        }

        &:after{
          background: $meuteal;
        }
      }

      &[href="#/contact"]{
        &:hover{
          color: $greenfrog;
        }

        &:after{
          background: $greenfrog;
        }
      }

    }
  }
}

// .toogle-nav{
//   display: block;
//   cursor: pointer;

//   @media (min-width: $breakpoint-sm) {
//     display: none;
//   }
// }

@keyframes stripes {
  to {
    background-size:100% 100%;
  }
}