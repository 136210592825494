.section{
  background-color: $white;
  padding: 30px 0;

  .title{
    @include text-1();
    text-align: center;
    margin: 0 0 30px 0;
  }

  .text{
    @include text-3();
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;

    @media (min-width: $breakpoint-sm) {
      font-size: 22px;
    }
  }

  // @import 'Items';
}
